.onboarding-type {
  display: flex;
  flex: 1;
  list-style-type: none;
}

.onboarding-type li {
  display: flex;
  width: 50%;

  margin: 0.5em;
  padding: 0.5em;

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1em;
  border: 1px solid gray;
  cursor: pointer;
}

.onboarding-type li:hover,
.onboarding-type li.selected {
  border-color: black;
  background-color: white;
}

.onboarding-type input {
  display: block;
  margin-right: 2em;
  padding: 1em;
  width: 2em;
  cursor: pointer;
}

.onboarding-type div {
  width: 90%;
}

.onboarding-type label {
  display: inline-flex;
  margin-bottom: 0.2em;
}

.onboarding-type p {
  font-size: 0.6em;
  color: dimgray;
}

.onboarding-type .unavailable {
  color: red;
}
