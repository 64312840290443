.loading-component {
  display: flex;
  margin: 0;
  padding: 0;
}

.loading-component > div {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin: 0 0.3em;
  background: #6859a3;
  animation: bounce 1s infinite ease-in-out;
}

.loading-component > div:nth-of-type(1) {
  z-index: 1;
}

.loading-component > div:nth-of-type(2) {
  animation-delay: 0.5s;
}

.loading-component > div:nth-of-type(3) {
  animation-delay: 1s;
}

@keyframes bounce {
  0%,
  15% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -0.5em);
    background: #72c2c2;
  }
  85%,
  100% {
    transform: translate(0, 0);
  }
}
