.estimate-swap-component {
  width: 100%;
  flex-direction: row;
}

.estimate-swap-component form {
  display: flex;
  flex-direction: column;
}

.estimate-swap-component form > div {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 0.5em;
}

.estimate-swap-component .converter label {
  margin-right: auto;
}

.estimate-swap-component .converter input {
  width: 50%;
  text-align: end;
  font-family: monospace;
}

.estimate-swap-component form .currency-selector-component,
.estimate-swap-component form input {
  margin-left: 0.5em;
}

.estimate-swap-component dl {
  font-size: 0.6em;
  font-family: monospace;
}

.estimate-swap-component dl > div {
  margin: 0.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-swap-component dt {
  color: dimgray;
  margin-left: 3em;
  font-size: 0.9em;
}

.estimate-swap-component dd b {
  font-size: 1.5em;
}

.estimate-swap-component .button-bar {
  display: flex;
  justify-content: flex-end;
}

.estimate-swap-component .button-bar .explain {
  font-size: 0.8em;
  color: dimgray;
  margin-right: 2em;
}

.estimate-swap-component button {
  display: flex;
  margin-left: 1em;
  width: 8rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
}

.estimate-swap-component .refresh-quote-btn {
  color: black;
  background-color: white;
}

.estimate-swap-component .price-impact {
  font-size: 0.4em;
  font-style: italic;
}
