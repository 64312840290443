.navigation-component {
  border-right: 1px solid lightgray;
}

.navigation-component ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navigation-component li {
  padding: 0.5em;
  margin: 0.5em 0;
}

.navigation-component li:has(a:hover) {
  border-right: 2px solid grey;
}

.navigation-component li.selected {
  border-right: 2px solid black;
}

.navigation-component a {
  padding: 0.5em;
}

.navigation-component a:hover {
  border-radius: 1em;
  background-color: lightgray;
}
