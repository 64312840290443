.welcome-page .intro {
  color: dimgray;
  margin-bottom: 1em;
  font-size: 1.1em;
  line-height: 1.5em;
}

.welcome-page {
  font-family: "Inter";
}

.welcome-page .button-bar {
  display: flex;
  justify-content: flex-end;
}
