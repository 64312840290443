.view-balances-component > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.5em 0;
}

.view-balances-component div:not(:last-of-type) {
  border-bottom: 1px solid lightgray;
}

.view-balances-component dl {
  width: 100%;
  align-self: center;

  font-family: monospace;
}

.view-balances-component dl > div {
  padding: 0.5em 0;
}

.view-balances-component dt {
  padding-bottom: 0.5em;
  font-size: 0.5em;
}

.view-balances-component dd {
  display: flex;
  font-size: 0.8em;
  align-items: flex-end;
}
