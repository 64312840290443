.pending-transactions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: lightblue;
}

.spacer {
  height: 2em;
}
