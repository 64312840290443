.transfer-component form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.transfer-component form > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.5em;
}

.transfer-component form > div button {
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 1em;
  margin-right: 1em;
  border-radius: 1em;
}
.transfer-component form > div button:hover {
  background-color: darkgray;
  color: white;
}

.transfer-component form > div label {
  flex: 0.5;
  margin-right: auto;
}

.transfer-component form > div input {
  flex: 0.5;
  text-align: end;
  font-family: monospace;
}

.transfer-component .currency-selector-component {
  margin-left: 0.5em;
}

.transfer-component .button-bar {
  display: flex;
  justify-content: flex-end;
}

.transfer-component .button-bar button {
  display: flex;
  width: 8rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
}

.transfer-component form .info-field {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
}

.transfer-component form .info-field a {
  font-size: 1em;
  text-decoration: underline;
}

.transfer-component form .error-field {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  color: red;
  padding-top: 0.2em;
  font-size: 0.8em;
}
