.view-liquidity-component {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.view-liquidity-component dl > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.5em 0;
}

.view-liquidity-component div:not(:last-of-type) {
  border-bottom: 1px solid lightgray;
}

.view-liquidity-component dl {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: monospace;
}

.view-liquidity-component dt {
  padding-bottom: 0.5em;
  font-size: 0.5em;
}

.view-liquidity-component dd {
  font-size: 0.8em;
}

.view-liquidity-component .explanation {
  font-size: 0.4em;
  opacity: 0.8;
  margin-bottom: 2em;
}
