.banner-component {
  display: flex;
  width: 100%;
  margin: 1em;
  border-radius: 1em;
  padding: 1em;
  align-items: center;
  justify-content: space-between;
  color: black;
  background-color: lightblue;
}

.banner-component button[type="button"] {
  margin: 0;
}

.banner-component.warning {
  color: white;
  background-color: orange;
}

.banner-component.error {
  color: white;
  background-color: red;
}
