.header-component {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.2rem 0;
  border-bottom: 1px solid black;
}

.header-component .name {
  margin-right: 1rem;
}

.header-component h1 {
  margin: 0 auto 1rem 0;
  display: flex;
  align-items: center;
}

.header-component h1 img {
  height: 3rem;
  margin-right: 1rem;
}
