.rfq-component .explanation {
  font-size: 0.4em;
  opacity: 0.8;
  margin-bottom: 2em;
}

.rfq-component form {
  display: flex;
  flex-direction: column;
}

.rfq-component form > div {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 0.5em;
}

.rfq-component .requestor label {
  margin-right: auto;
}

.rfq-component .requestor input {
  width: 50%;
  text-align: end;
  font-family: monospace;
}

.rfq-component form .currency-selector-component,
.rfq-component form input {
  margin-left: 0.5em;
}

.rfq-component dl {
  font-size: 0.6em;
  font-family: monospace;
}

.rfq-component dl > div {
  margin: 0.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rfq-component dt {
  color: dimgray;
  margin-left: 3em;
  font-size: 0.9em;
}

.rfq-component dd b {
  font-size: 1.5em;
}
