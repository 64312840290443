.receipt-component dl {
  margin: 0 auto;
  width: 100%;
  padding: 2rem;
  border-radius: 2rem;
  font-size: 1.5rem;
  background-color: whitesmoke;
}

.receipt-component dl > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem 0;
}

.receipt-component div:not(:last-of-type) {
  border-bottom: 1px solid lightgray;
}

.receipt-component dl {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: monospace;
}

.receipt-component dt {
  padding-bottom: 0.5em;
  font-size: 0.5em;
  opacity: 0.8;
  text-transform: uppercase;
}

.receipt-component dd {
  font-size: 0.8em;
  display: flex;
}

.receipt-component dd a {
  font-size: 1rem;
}

.receipt-component .status-ok {
  color: green;
}

.receipt-component .action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.receipt-component .action-buttons button {
  margin-left: 1em;
}
