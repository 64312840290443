.onboarding-page form {
  display: flex;
  flex-direction: column;
  flex: 0.6;
}

.onboarding-page form .error-field {
  color: red;
  padding-top: 0.2em;
  font-size: 0.8em;
}

.onboarding-page form label {
  margin: 1em 0 0.5em 0;
}

.onboarding-page .intro {
  color: dimgray;
  margin-bottom: 1em;
  font-size: 1.1em;
  line-height: 1.5em;
}

.onboarding-page fieldset {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-bottom: 1em;
  border: 0;

  font-family: "Inter";
  font-size: 0.5em;
}

.onboarding-page fieldset legend {
  font-size: 1.2em;
  font-weight: bold;
}

.onboarding-page fieldset > div:has(label) {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1em;
}

.onboarding-page fieldset div:has(label) input,
.onboarding-page fieldset div:has(label) textarea {
  margin: 0;
}

.onboarding-page textarea {
  border-radius: 0.5em;
  padding: 1em;
}

.onboarding-page .onboarding-component button[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 3rem;
}
